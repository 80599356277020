import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { RichInput, Space, TextArea } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { FigmaEmbed, ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "textarea"
    }}>{`TextArea`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { TextArea } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=4344%3A1'} storybook={'/?path=/story/inputs-textarea--playground'} name={'TextArea'} vueStorybook={'/?path=/story/inputs-textarea--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`TextArea — это компонент многострочного поля ввода. Используется для ввода информации, которой не хватит одной строки компонента `}<a parentName="p" {...{
        "href": "/src-components-input"
      }}>{`Input`}</a>{`.`}</p>
    <p>{`При передаче пропа `}<inlineCode parentName="p">{`autosize`}</inlineCode>{` компонент будет подстраивать свою высоту под объем введенного текста.`}</p>
    <p>{`Стандартная ширина компонента – `}<inlineCode parentName="p">{`280px`}</inlineCode>{`. Если передать проп `}<inlineCode parentName="p">{`fullWidth`}</inlineCode>{`, компонент займет `}<inlineCode parentName="p">{`100%`}</inlineCode>{` ширины родителя.`}</p>
    <p>{`Компоненту возможно задать состояние ошибки с помощью пропа `}<inlineCode parentName="p">{`error`}</inlineCode>{`. Для добавления лейбла или сообщения ошибки, используйте компонент в связке с `}<a parentName="p" {...{
        "href": "/src-components-rich-input"
      }}>{`RichInput`}</a></p>
    <p>{`Прочие пропы прокидываются на `}<inlineCode parentName="p">{`textarea`}</inlineCode>{` элемент с классом `}<inlineCode parentName="p">{`hse-TextArea__textarea`}</inlineCode></p>
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = useState(\'\')\n  const onChange = e => setValue(e.target.value)\n  return (\n    <TextArea\n      onChange={onChange}\n      value={value}\n      placeholder=\"Адрес регистрации\"\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      RichInput,
      Space,
      TextArea,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('');

        const onChange = e => setValue(e.target.value);

        return <TextArea onChange={onChange} value={value} placeholder="Адрес регистрации" mdxType="TextArea" />;
      }}
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node={'?node-id=9786%3A3101'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node={'?node-id=9786%3A3121'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "автоматическая-высота"
    }}>{`Автоматическая высота`}</h3>
    <p>{`При передаче пропа `}<inlineCode parentName="p">{`autosize`}</inlineCode>{` компонент будет подстраивать свою высоту под объем введенного текста.`}</p>
    <p>{`Вы можете ограничить максимальную высоту компонента в этом режиме с помощью css-свойства `}<inlineCode parentName="p">{`max-height`}</inlineCode>{` на элементе `}<inlineCode parentName="p">{`textarea`}</inlineCode>{`,
передав его через проп `}<inlineCode parentName="p">{`inputStyle`}</inlineCode>{` или css-класс `}<inlineCode parentName="p">{`classes.textArea`}</inlineCode>{`.`}</p>
    <Playground __position={4} __code={'() => {\n  const [value, setValue] = useState(\'\')\n  const onChange = e => setValue(e.target.value)\n  return (\n    <TextArea\n      onChange={onChange}\n      value={value}\n      placeholder=\"Адрес регистрации\"\n      autosize\n      inputStyle={{ maxHeight: 200 }}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      RichInput,
      Space,
      TextArea,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('');

        const onChange = e => setValue(e.target.value);

        return <TextArea onChange={onChange} value={value} placeholder="Адрес регистрации" autosize inputStyle={{
          maxHeight: 200
        }} mdxType="TextArea" />;
      }}
    </Playground>
    <h3 {...{
      "id": "изменение-размера-пользователем"
    }}>{`Изменение размера пользователем`}</h3>
    <p>{`По умолчанию пользователь может изменять размер поля ввода по вертикали.
Это компромиссное решение, позволяющее пользователю легче вводить большие объемы текста.`}</p>
    <p>{`При необходимости это поведение можно отключить с помощью пропа `}<inlineCode parentName="p">{`resize='none'`}</inlineCode>{`.`}</p>
    <p>{`Возможность разрешения изменения ширины поля ввода по горизонтали в данный момент не поддерживается.
Если вам нужна такая функция, `}<a parentName="p" {...{
        "href": "/contacts"
      }}>{`напишите нам`}</a>{` и расскажите подробнее о вашем юзкейсе.`}</p>
    <Playground __position={5} __code={'() => {\n  const [value, setValue] = useState(\'\')\n  const onChange = e => setValue(e.target.value)\n  return (\n    <TextArea\n      onChange={onChange}\n      value={value}\n      placeholder=\"Адрес регистрации\"\n      resize={\'none\'}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      RichInput,
      Space,
      TextArea,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('');

        const onChange = e => setValue(e.target.value);

        return <TextArea onChange={onChange} value={value} placeholder="Адрес регистрации" resize={'none'} mdxType="TextArea" />;
      }}
    </Playground>
    <h3 {...{
      "id": "ширина"
    }}>{`Ширина`}</h3>
    <p>{`Стандартная ширина компонента – `}<inlineCode parentName="p">{`280px`}</inlineCode>{`. Если передать проп `}<inlineCode parentName="p">{`fullWidth`}</inlineCode>{`, то компонент займет `}<inlineCode parentName="p">{`100%`}</inlineCode>{` ширины родителя.`}</p>
    <p>{`Ширину можно также изменить с помощью css-класса, назначив его через проп `}<inlineCode parentName="p">{`className`}</inlineCode>{`, либо передав css-свойства через атрибут `}<inlineCode parentName="p">{`style`}</inlineCode>{`.`}</p>
    <Playground __position={6} __code={'() => {\n  const [value, setValue] = useState(\'\')\n  const onChange = e => setValue(e.target.value)\n  return (\n    <TextArea\n      onChange={onChange}\n      value={value}\n      placeholder=\"Адрес регистрации\"\n      fullWidth\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      RichInput,
      Space,
      TextArea,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('');

        const onChange = e => setValue(e.target.value);

        return <TextArea onChange={onChange} value={value} placeholder="Адрес регистрации" fullWidth mdxType="TextArea" />;
      }}
    </Playground>
    <h3 {...{
      "id": "состояние-ошибки"
    }}>{`Состояние ошибки`}</h3>
    <p>{`Компоненту возможно задать состояние ошибки с помощью пропа `}<inlineCode parentName="p">{`error`}</inlineCode>{`. Для добавления лейбла или сообщения ошибки, используйте компонент в связке с `}<a parentName="p" {...{
        "href": "/src-components-rich-input"
      }}>{`RichInput`}</a></p>
    <Playground __position={7} __code={'() => {\n  const [value, setValue] = useState(\'\')\n  const onChange = e => setValue(e.target.value)\n  return (\n    <>\n      <TextArea\n        onChange={onChange}\n        value={value}\n        placeholder=\"Адрес регистрации\"\n        error\n      />\n      <Space size={Space.Size.small} vertical />\n      <RichInput\n        component={TextArea}\n        onChange={onChange}\n        value={value}\n        placeholder=\"Адрес регистрации\"\n        errorMessage=\"Некорректный адрес\"\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      RichInput,
      Space,
      TextArea,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('');

        const onChange = e => setValue(e.target.value);

        return <>
      <TextArea onChange={onChange} value={value} placeholder="Адрес регистрации" error mdxType="TextArea" />
      <Space size={Space.Size.small} vertical mdxType="Space" />
      <RichInput component={TextArea} onChange={onChange} value={value} placeholder="Адрес регистрации" errorMessage="Некорректный адрес" mdxType="RichInput" />
    </>;
      }}
    </Playground>
    <h3 {...{
      "id": "отображение-ограничения-длины"
    }}>{`Отображение ограничения длины`}</h3>
    <p>{`При включении ограничения длины через проп `}<inlineCode parentName="p">{`maxLength`}</inlineCode>{` в углу компонента TextArea
автоматически отображается счетчик количества оставшихся символов,
позволяющий пользователю лучше ориентироваться при вводе текста в ограниченное поле.`}</p>
    <p>{`Обратите внимание, что наличие пропа `}<inlineCode parentName="p">{`maxLength`}</inlineCode>{` не отменяет необходимость внешней валидации формы перед её отправкой.
В связи с этим состояние `}<inlineCode parentName="p">{`error`}</inlineCode>{` не включается автоматически при нарушении ограничения длины.
Вам следует самостоятельно определять ошибочность поля при валидации формы и включать состояние `}<inlineCode parentName="p">{`error`}</inlineCode>{` в нужный вам момент,
в том числе при каких-либо других ошибках.`}</p>
    <p>{`Счетчик работает как в контролируемом, так и в неконтролируемом варианте использования компонента.
Чтобы отключить счетчик, передайте проп `}<inlineCode parentName="p">{`counter={false}`}</inlineCode>{`.`}</p>
    <Playground __position={8} __code={'<TextArea maxLength={100} placeholder={\'Адрес регистрации\'} />'} __scope={{
      props,
      DefaultLayout,
      useState,
      RichInput,
      Space,
      TextArea,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <TextArea maxLength={100} placeholder={'Адрес регистрации'} mdxType="TextArea" />
    </Playground>
    <h3 {...{
      "id": "отображение-произвольного-счетчика"
    }}>{`Отображение произвольного счетчика`}</h3>
    <p>{`Для отображения произвольного счетчика передайте в проп `}<inlineCode parentName="p">{`counter`}</inlineCode>{` его текущее значение.
Конкретную механику поведения счетчика в этом случае необходимо реализовать самостоятельно.
Рекомендуем создать отдельный компонент-обертку над TextArea, содержащий необходимую механику,
и переиспользовать его в рамках продукта.`}</p>
    <p>{`С помощью пропа `}<inlineCode parentName="p">{`counterError`}</inlineCode>{` можно акцентировать внимание на счетчике, выделив его в ошибочном состоянии.
Вывести сообщение о превышении количества символов можно с помощью компонента `}<a parentName="p" {...{
        "href": "/src-components-rich-input"
      }}>{`RichInput`}</a>{`.`}</p>
    <Playground __position={9} __code={'() => {\n  const [value, setValue] = useState(\'\')\n  const maxLength = 200\n  const counter = maxLength - value.length\n  return (\n    <div style={{ display: \'flex\', alignItems: \'flex-start\' }}>\n      <TextArea\n        counter={counter + \'/\' + maxLength}\n        counterError={counter < 0}\n        placeholder={\'Адрес регистрации\'}\n        value={value}\n        onChange={e => setValue(e.target.value)}\n      />\n      <Space size={Space.Size.small} horizontal />\n      <RichInput\n        component={TextArea}\n        counter={counter + \'/\' + maxLength}\n        counterError={counter < 0}\n        label={\'Адрес регистрации\'}\n        placeholder={\'Введите адрес регистрации\'}\n        value={value}\n        errorMessage={\n          counter < 0 &&\n          `Превышена максимальная длина в ${maxLength} символов`\n        }\n        onChange={e => setValue(e.target.value)}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      RichInput,
      Space,
      TextArea,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('');
        const maxLength = 200;
        const counter = maxLength - value.length;
        return <div style={{
          display: 'flex',
          alignItems: 'flex-start'
        }}>
        <TextArea counter={counter + '/' + maxLength} counterError={counter < 0} placeholder={'Адрес регистрации'} value={value} onChange={e => setValue(e.target.value)} mdxType="TextArea" />
        <Space size={Space.Size.small} horizontal mdxType="Space" />
        <RichInput component={TextArea} counter={counter + '/' + maxLength} counterError={counter < 0} label={'Адрес регистрации'} placeholder={'Введите адрес регистрации'} value={value} errorMessage={counter < 0 && `Превышена максимальная длина в ${maxLength} символов`} onChange={e => setValue(e.target.value)} mdxType="RichInput" />
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={TextArea} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на элемент `}<inlineCode parentName="p">{`textarea`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "css-классы"
    }}>{`CSS классы`}</h2>
    <p>{`Вы можете использовать данный компонент без библиотеки компонентов, а лишь подключив css стили. Ниже приведен пример использования. Компоненты сверстаны по методологии `}<a parentName="p" {...{
        "href": "https://bem.info/"
      }}>{`BEM`}</a>{`: миксуя необходимые модификаторы вы можете задать компоненту определенное состояние.`}</p>
    <p>{`Класс блока: `}<inlineCode parentName="p">{`hse-TextArea`}</inlineCode></p>
    <h3 {...{
      "id": "модификаторы"
    }}>{`Модификаторы`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Модификатор`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-TextArea_error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Состояние error у TextArea`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-TextArea_disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает TextArea неработоспособной`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-TextArea_fullWidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ширина TextArea 100%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-TextArea_focus`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Состояние focus у TextArea`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-TextArea_autosize`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Автоматическое изменение размеров TextArea`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "элементы"
    }}>{`Элементы`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Элемент`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-TextArea__textarea`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TextArea`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-TextArea__counter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Счетчик ошибок`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "модификаторы-элементов"
    }}>{`Модификаторы элементов`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Элемент`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-TextArea__textarea_autosize`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Автоматическое изменение размеров TextArea`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-TextArea__textarea_disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает TextArea неработоспособной`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-TextArea__textarea_hasCounter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Наличие счетчика`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-TextArea__counter_error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Счетчик ошибок`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h3>
    <Playground __position={11} __code={'<div className=\"hse-TextArea hse-Input hse-TextArea_fullWidth hse-Input_fullWidth hse-TextArea_focus hse-Input_focus hse-TextArea_autosize\">\n  <textarea className=\"hse-TextArea__textarea hse-TextArea__textarea_autosize hse-TextArea__textarea_hasCounter\" />\n  <div className=\"hse-TextArea__counter hse-TextArea__counter_error\">8</div>\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      RichInput,
      Space,
      TextArea,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div className='hse-TextArea hse-Input hse-TextArea_fullWidth hse-Input_fullWidth hse-TextArea_focus hse-Input_focus hse-TextArea_autosize'>
      <textarea className='hse-TextArea__textarea hse-TextArea__textarea_autosize hse-TextArea__textarea_hasCounter' />
      <div className='hse-TextArea__counter hse-TextArea__counter_error'>
        8
      </div>
    </div>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      